<script setup lang="ts">
import type {
  ILeaseReportTemplate,
  ICreateUpdateLeaseReportTemplateDefinitionRequest,
} from '@portfolio'

import { VueFinalModal } from 'vue-final-modal'
import { useApiSaveReportTemplate } from '@reports/composables/useApiReportTemplates'
import type { FormKitNode } from '@formkit/core'
import { FetchError } from 'ofetch'
import { toast } from 'vue3-toastify'
import { ApiError } from '@shared'

interface ISaveTemplateForm {
  name: string
  description: string | undefined
}

interface Props {
  divisionId: string
  template: ILeaseReportTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  save: [templateId: string]
  close: []
  cancel: []
}>()

/* === SAVE === */
const form = ref()
const { mutateAsync: saveTemplate, isPending: isSaving } =
  useApiSaveReportTemplate(props.divisionId)
const onSubmit = async (
  { name, description }: ISaveTemplateForm,
  node: FormKitNode,
) => {
  try {
    const promise = saveTemplate({
      name,
      description,
      template: props.template
        .template as ICreateUpdateLeaseReportTemplateDefinitionRequest,
      sector: props.template.sector,
    })

    const response = await toast.promise(
      promise,
      {
        pending: 'Saving template...',
        success: 'Template saved successfully',
        error: 'Error saving template',
      },
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      },
    )

    emit('save', response.id as string)
  } catch (error) {
    console.error(error)

    // Normalize errors to FormKit and display them in the form
    if (error instanceof ApiError && error.data) {
      const { localErrors, childErrors } = requestToFormkitErrors(error.data)
      node.setErrors(localErrors, childErrors)
    }
  }
}
/* === SAVE === */

/* === LISTENERS ===*/
const onClose = () => emit('close')
const onCancel = () => emit('cancel')
/* === LISTENERS ===*/
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="
      {
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': '-translate-y-6 opacity-0',
        'enter-to-class': 'translate-y-0 opacity-100',
        'leave-active-class': 'transition duration-300 ease-in-out transform',
        'leave-to-class': 'translate-y-6 opacity-0',
        'leave-from-class': '-translate-y-0 opacity-100',
      } as any
    "
  >
    <ModalContainer
      title="Save custom template"
      :close-on-click="false"
      container-class="max-w-md bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-750"
      @close="onClose"
    >
      <div class="flex flex-col transition-all">
        <FormKit
          ref="form"
          type="form"
          :actions="false"
          form-class="flex flex-col gap-4"
          @submit="onSubmit"
        >
          <FormKit
            type="text"
            name="name"
            label="Template Name"
            placeholder="Template Name"
          />
          <FormKit
            type="textarea"
            name="description"
            label="Description"
            placeholder="Description"
            :rows="4"
          />
        </FormKit>

        <div class="mt-4 flex justify-end gap-2">
          <Button
            color="transparentSecondary"
            class="flex justify-center self-end whitespace-nowrap"
            @click="onCancel"
          >
            Back
          </Button>
          <Button :loading="isSaving" @click="form?.node.submit()">
            Confirm
          </Button>
        </div>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
